@import "../../assets/styles/variables";

.art {
    margin-top: $gutter3x;
    h1 {
        width: 100%;
        text-align: center;
        padding-top: $gutter;
    }
    .text-orange {
        color: $orange;
    }

    &__wrapper {
        padding: $gutter;
        line-height: 0;
        -webkit-column-count: 1;
        -webkit-column-gap: $gutter;
        -moz-column-count: 1;
        -moz-column-gap: $gutter;
        column-count: 1;
        column-gap: $gutter;

        img {
            width: 100%;
            height: auto;
            margin: $gutter 0;
            background: url('../../assets/images/img-placeholder.png') no-repeat center center;
            background-size: cover;
            min-height: 300px;
            min-width: 200px;
        }
    }

    @media screen and (min-width: 769px) {
        margin-top: 0;
        &__wrapper {
            padding: $gutter2x;
            -webkit-column-count: 2;
            -moz-column-count: 2;
            column-count: 2;
        }
    }
}
