@import "../../assets/styles/variables";
@import "../../assets/styles/sudo";
@import "../../assets/styles/flex";

.projects {
    animation: fade-in 700ms ease-in-out;

    &__hero {
        height: 500px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    }

    &__title {
        @include flex(column, center, center);
        padding: $gutter $gutter2x 0;
        gap: $gutter;

        h1 {
            grid-area: title;
            animation: fade-in 700ms ease-in-out;

            &.has-award {
                position: relative;
                padding-left: $gutter3x;

                &::before {
                    @include sudo-fill(absolute, 50%, 0, 0, 0);
                    transform: translateY(-50%);
                    height: 36px;
                    width: 36px;
                    background: url("https://png.pngtree.com/png-vector/20230225/ourmid/pngtree-gold-blue-best-award-badge-with-stars-and-rice-symbol-png-image_6587280.png")
                        center center no-repeat;
                    background-size: contain;
                }
            }
        }

        h4 {
            grid-area: location;
            color: $orange;
            animation: fade-in 700ms ease-in-out;
        }

        ul {
            grid-area: details;
            li {
                line-height: 1.5;
            }
        }
    }

    &__content {
        padding: $gutter;
        h2 {
            margin-top: $gutter2x;
            margin-bottom: $gutter;
        }
    }

    &__carousel {
        line-height: 0;
        -webkit-column-count: 3;
        -webkit-column-gap: $gutter1_4;
        -moz-column-count: 3;
        -moz-column-gap: $gutter1_4;
        column-count: 2;
        column-gap: $gutter1_4;

        img {
            width: 100%;
            height: auto;
            margin: $gutter1_4 0;
        }
    }

    @media screen and (min-width: 679px) {
        &__title {
            display: grid;
            grid-template-areas: "title details" "location details";
            gap: $gutter1_2;
            padding: $gutter2x;
            justify-content: space-between;
        }

        &__content {
            padding: $gutter2x;
            p {
                margin: $gutter 0;
            }
        }
    }
}
