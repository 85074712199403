.card {
  @include flex(column, flex-start, center);
  cursor: pointer;
  gap: $gutter1_2;
  background-color: $white;
  border-radius: $border-radius-medium;
  box-shadow: $box-shadow-large;
  padding: $gutter1_2;
  max-width: 550px;

  &__overlay{
    position: relative;
    overflow: hidden;
    height: 320px;
    width: 100%;
    border-radius: $border-radius-normal;
    background: url('../images/img-placeholder.png') no-repeat center center;
    background-size: cover;
    min-width: min(450px, 80vw);
    &:hover {
      .card__img {
        transform: scale(1.1);
        filter: brightness(40%);
      }

      .card__title, .card__subtitle, .card__redirect {
        opacity: 1;
      }
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: fill;
    transition: $transition-in-out;
  }

  &__title{
    @include element-position(absolute, 45%, unset, unset, 50%);
    @include line-clamp(2);
    transform: translate(-50%, -50%);
    color: $white;
    opacity: 0;
    font-size: $font-size-s;
    font-weight: $font-weight700;
    text-transform: uppercase;
  }

  &__subtitle {
    @include line-clamp(1);
    @include element-position(absolute, 52%, 32px, unset, 32px);
    opacity: 0;
    color: $white;
    font-size: $font-size-xs;
    font-weight: $font-weight500;
    text-align: center
  }

  &__redirect {
    @include element-position(absolute, unset, 32px, 16px, unset);
    opacity: 0;
    color: $white;
    font-size: $font-size-xxs;
    transition: $transition-in-out;
    transition-delay: 300ms;
  }
}