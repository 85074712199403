@import "../../assets/styles/variables";

.null-state {
    margin: 0 auto;
    background: url('../../assets/images/not-found-state.jpeg') center center no-repeat;
    background-size: contain;
    min-height: 350px;
    margin-top: calc(80px + $gutter4x);
}

.empty-state {
    margin: 0 auto;
    background: url('../../assets/images/null-state.png') no-repeat center center;
    background-size: contain;
    min-height: 350px;
    margin-top: calc(80px + $gutter4x);
}