@import "../../assets/styles/variables";
@import "../../assets/styles/sudo";
@import "../../assets/styles/flex";


.btn-close, .btn-open {
    @include element-position(absolute, 16px, 16px, unset, unset);
    background: none;
    border: 0;
    font-size: $font-size-m;
    z-index: 4;
    @media screen and (min-width: 769px) {
        display: none;
    }
}

.btn-close {
    color: $white;
}

.text-white {
    color: $white
}

.navbar {
    @include element-position(fixed, 0, 0, 0, 0);
    @include flex(column, center, flex-start);
    gap: $gutter3x;
    background-color: $orange;
    padding-top: 80px;
    box-shadow: $box-shadow-large;
    transform: translateY(-100%);
    transition: $transition-in-out;
    font-family: Roboto, sans-serif;
    z-index: 5;
    
    &__left {
        @include flex(column, center, flex-start);
        text-align: center;
        li{
            height: 100%;
        }
        img {
            height: 100%;
            object-fit: contain;
        }
        .logo {
            a {
                h1 {
                    letter-spacing: 2px;
                    span{
                        color: $orange
                    }
                }
            }
        }
    }

    &__right {
        @include flex(column, center, center);
        gap: $gutter2x;
        .social-icons {
            @include flex(row, center, space-evenly);
            gap: $gutter
        }
        .search-bar {
            @include flex(row, center, center);
            gap: $gutter1_2;
            &__text {
                background-color: $body-bg;
                width: 100%;
                border-radius: $border-radius-normal;
                transition: $transition-in-out;
            }
            
            &__btn {
                color: $white;
                background-color: $orange;
                border-radius: $border-radius-normal;

                &:hover {
                    background: darken($color: $orange, $amount: 2);
                }
            }
        }
    }

    &.show {
        transform: translateY(0);
    }

    @media screen and (min-width: 769px){
        padding: 0 $gutter3x;
        padding-top: 0;
        flex-direction: row;
        background-color: $white;
        justify-content: space-between;
        bottom: unset;
        height: 80px;
        transform: translateY(0);

        &__left {
            flex-direction: row;
            height: 100%;

            .logo {
                margin-right: $gutter3x;
            }

            li {
                display: inline-block;
                min-width: 160px;
                height: 100%;
                border-left: 1px solid $stroke;

                &:last-of-type {
                    border-right: 1px solid $stroke
                }

                & > div, & > a {
                    @include flex(row, center, center);
                    height: 100%;
                    width: 100%;
                }

                &:hover {
                    color: $white;
                    background-color: $orange;

                    & > a {
                        color: $white;
                    }
                }
            }
            img {
                border-right: 1px solid $textColor; 
                padding-right: $gutter;
            }

            .dropdown {
                position: relative;
                display: inline-block;    
                .icon {
                    font-size: $font-size-xxs;
                    margin-left: $gutter1_2;
                    transition: $transition-in-out;
                }
    
                &__content {
                    position: relative;
                    display: block;
                    opacity: 0;
                    background-color: $orange;
                    min-width: 200px;
                    max-width: 250px;
                    min-height: 0;
                    pointer-events: none;
                    transition: $transition-in-out;
    
                    a {
                        display: block;
                        color: $white;
                        width: 100%;
                        text-align: start;
                        padding: $gutter;
                        transition: $transition-in-out;
                        
                        &:hover {
                            color: $orange;
                            background-color: $white;
                        }
                    }
                }
    
                &:hover {
                    .dropdown__content {
                        pointer-events: all;
                        min-height: max-content;
                        height: max-content;
                        opacity: 1;
                    }
                    .icon {
                        transform: rotate(180deg);
                    }
                }
            }
        }
        &__right {
            flex-direction: row;
            
            .search-bar {
                flex-direction: row;
            }
        }
    }

    @media screen and (max-width: 768px) {
        &__left {
            width: 100%;
            overflow-y: auto;
            li {
                width: 100%;

                & > div:not(.dropdown__content), & > a{
                    display: block;
                    padding: $gutter;
                    text-align: start;
                    font-size: $font-size-base;
                    color: $white;
                    width: 100%;

                    svg {
                        float: right;
                    }
                } 
            } 

            .logo {
                margin-bottom: $gutter2x;
                color: $white;
                a {
                    h1 {
                        span {
                            color: $white;
                        }
                    }
                }
            }

            .dropdown {
                .icon {
                    transform: rotate(180deg);
                    transition: $transition-in-out
                }

                &__content {
                    @include flex(column, flex-start, center);
                    max-height: 500px;
                    opacity: 1;
                    transition: $transition-in-out;

                    a {
                        color: $white;
                        width: 100%;
                        text-align: start;
                        padding: $gutter;
                        padding-left: $gutter2x;
                    }
                }

                &:not(.show) {
                    .icon {
                        transform: rotate(0);
                    }
                    .dropdown__content {
                        max-height: 0;
                        overflow: hidden;
                        opacity: 0;
                    }
                } 
            }
        }
        &__right {
            a {
                color: $white;
            }
        }
    }
}