@font-face {
    font-family: "Inter";
    src: url('../fonts/Inter/Inter-VariableFont_slnt\,wght.ttf');
}

@font-face {
    font-family: "Roboto";
    src: url('../fonts/Roboto/Roboto-Regular.ttf');
}

@font-face {
    font-family: "Poppins";
    src: url('../fonts/Poppins/Poppins-Regular.ttf');
}

//Font Family
$font-family: Inter, Roboto, Poppins, Ubuntu, sans-serif;

// Colors
$body-bg:           #faf7f6;
$body-fg:           #87CEEB; 
$white:             #FFFFFF;
$black:             #000000;
$stroke:            #DDE5EA;
$strokeVariant:     #BACBD5;
$bgColorVariant:    #f5f5f5;
$textColor:         #1C4980;
$textColorVariant:  #8DA4BF;
$green:             #05C165;
$blue:              #0073E6;
$blueSubtle:        #E5F1FC;
$mustard:           #f1e610;
$orange:            #c86508;
$orangeSubtle:      #FBEBEA;
$chipsBlue:         #DDEDFF;

// Font Size
$font-size-base:      1rem;
$font-size-xxl:         $font-size-base * 4;                //64px
$font-size-xl:         $font-size-base * 3;                //48px
$font-size-l:         $font-size-base * 2;                //32px
$font-size-m:         $font-size-base * 1.5;              //24px
$font-size-s:        $font-size-base * 1.125;             //18px
$font-size-sm:        $font-size-base * 1.25;             //20px
$font-size-default:   $font-size-base * .875;             //14px
$font-size-xs:        $font-size-base * .75;              //12px
$font-size-xxs:        $font-size-base * .625;            //10px


//Gutters
$gutter:      1rem;
$gutter1_4:   $gutter * 0.25;                         //4px
$gutter1_2:   $gutter * 0.5;                          //8px
$gutter10:    $gutter * 0.625;                       //10px
$gutter12:    $gutter * 0.75;                        //12px
$gutter20:    $gutter * 1.25;                        //20px
$gutter24:    $gutter * 1.5;                         //24px
$gutter2x:    $gutter * 2;                           //32px
$gutter3x:    $gutter * 3;                           //48px
$gutter4x:    $gutter * 4;                           //64px
$gutter100:   $gutter * 6.26;                        //100px


//Font Weights
$font-weight100: 100;
$font-weight200: 200;
$font-weight300: 300;
$font-weight400: 400;
$font-weight500: 500;
$font-weight600: 600;
$font-weight700: 700;
$font-weight800: 800;
$font-weight900: 900;

// Border Radius
$border-radius-small:   4px;
$border-radius-normal:  8px;
$border-radius-medium:  12px;
$border-radius-pill:  22px;
$border-radius-button:  50px;
$border-radius-rounded:  50%;

// Transitions
$transition-long:       500ms all ease-in-out;
$transition-in-out:     200ms all ease-in-out;
$transition-in:         200ms all ease-in;
$transition-out:        200ms all ease-out;

$box-shadow-large:    0px -4px 50px 0px rgba(0, 0, 0, 0.09);

