@import "../../assets/styles/variables";
@import "../../assets/styles/sudo";
@import "../../assets/styles/flex";

.landing {
    &__showcase {
        @include flex(column, center, center);
        padding: $gutter2x $gutter;

        .cards {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            gap: $gutter2x;
            margin-top: $gutter;
        }
    }

    @media screen and (min-width: 769px) {
        &__showcase {
            .cards {
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }
}
