@import "../../assets/styles/variables";

.team {
  position: relative;
  margin-top: $gutter3x;
  margin-bottom: $gutter20;

  h1,
  h2 {
    text-align: center;
    padding-top: $gutter;
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: $gutter;
    margin: 0;

    img {
      min-height: 400px;
      min-width: 100%;
      width: 100%;
      height: auto;
      margin: 0;
      background: url('../../assets/images/img-placeholder.png') no-repeat center center;
      background-size: cover;
    }
  }

  @media screen and (min-width: 769px) {
    margin-top: 0;
    &__wrapper {
      margin: 0 $gutter4x;
    }
  }

  .team_image {
    position: relative;
    height: 100vh; // Set the desired height

    @media screen and (min-width: 769px) and (max-width: 1024px) {
      height: 46vh; // Adjusted height for iPad screens between 769px and 1024px
      position:relative;
    }

    @media screen and (max-width: 768px) {
      height: 45vh;
    }
  }

  .team_image img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;

    @media screen and (max-width: 768px) {
      position: absolute;
    }
  }

  h1 {
    text-align: center;
    padding-top: $gutter;
    margin: $gutter 0;
    font-size: $font-size-xl;
  }
}
