@import "../../assets/styles/variables";
@import "../../assets/styles/sudo";
@import "../../assets/styles/flex";
@import "../../assets/styles/text-functions";

.news {
    @include flex(column, center, flex-start);
    background-color: $white;
    padding: $gutter;
    min-height: 100vh;
    padding-top: $gutter4x;
    animation: fade-in 700ms ease-in-out;

    &__title {
        padding: 0 $gutter;
    }
    
    &__card {
        cursor: pointer;
        position: relative;
        @include flex(column, flex-start, flex-start);
        gap: $gutter;
        background-color: $white;
        width: 100%;
        overflow: hidden;
        margin: 0 auto;
        padding: $gutter;

        &:hover {
            color: $textColor;

            .news__card__icon {
                opacity: 1;
            }
        }

        &:not(:last-child) {
            border-bottom: 1px solid $stroke;
        }

        h2 {
            grid-area: title;
            font-size: $font-size-s;
        }
        &__icon {
            opacity: 0;
            @include element-position(absolute, 16px, 16px, unset, unset);
            transition: $transition-in-out;
        }
        p {
            @include line-clamp(2)
        }
        span {
            grid-area: timeline;
        }
    }

    @media screen and (min-width: 769px){
        min-height: calc(100vh - 80px);
        padding-top: $gutter;
        row-gap: $gutter;
        &__wrapper {
            width: 65%;
        }
        &__card {
            h2 {
                font-size: $font-size-sm;
            }
        }
    }
}