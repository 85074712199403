*,
*::after,
*::before {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialised;
}

body {
  font-family: $font-family;
  font-size: $font-size-default;
  background-color: $body-bg;
  color: $textColor;
  margin: 0;
  padding: 0;
  line-height: normal;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
label {
  margin: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: unset;
  text-decoration: none;
}

:any-link {
  color: unset;
}

::placeholder {
  color: $strokeVariant;
}

a,
button {
  font-size: $font-size-default;
  transition: $transition-in-out;
  &.active,
  &:hover {
    color: $orange;
    transition: $transition-in-out;
  }
}

input {
  font-size: $font-size-default;
  padding: $gutter1_2 $gutter;
  background: transparent;
  outline: 0;
  border: 1px solid transparent;
  transition: $transition-in-out;

  &[type="submit"] {
    cursor: pointer;
    transition: $transition-in-out;
  }

  &:focus,
  &:focus-visible {
    border: 1px solid $textColor;
  }
}


textarea {
  font-family: $font-family;
  outline: 0;
  border: 1px solid transparent;
  transition: $transition-in-out;

  &:focus,
  &:focus-visible {
    border: 1px solid $textColor;
  }
}