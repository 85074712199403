@media screen and (min-width: 769px){
    .wrapper {
        margin-top: 80px;
    }
}

.btn {
    cursor: pointer;
    padding: 0;
    border: 0;
}

@keyframes fade-in {
    0% {
        opacity: 0;
        transform: translateY(-150px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}