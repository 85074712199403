@import "../../assets/styles/variables";

.carousel {
    &__img {
        object-fit: cover;
        background: url('../../assets/images/img-placeholder.png') no-repeat center center;
        background-size: cover;
        height: 100vh;
        width: 100%;
    }   

    .slick-dots {
        bottom: 32px;
        li {
            button:before {
                font-size: $font-size-xxs;
                color: $stroke;
                mix-blend-mode: color-dodge;
            }
            &.slick-active  {
                button:before {
                    color: $white;
                }
            }
        }
    }
    .slick-next, .slick-prev {
        z-index: 1;
        &::before {
            color: $white;
            font-size: $font-size-l;
            mix-blend-mode: difference;
        }
    }
    .slick-next {
        right: 24px;
    }
    .slick-prev {
        left: 16px;
    }

    @media screen and (min-width: 769px){
        &__img {
            height: calc(100vh - 80px);
        }
    }
}